import React from 'react';

import { ButtonVariant } from '~/components/Buttons/Types/constants';
import { HORIZONTAL_ALIGNMENT, IHeroWithVideo, VERTICAL_ALIGNMENT } from '~/components/hero/Hero.type';
import { HeroWithVideo } from '~/components/hero/HeroWithVideo';

export const BAYLOR_VIDEO = ({ clickHandler = () => {} }) => {
	const heroArgs: IHeroWithVideo = {
		buttonText: 'Shop New Seating',
		buttonLink: '/catalog/living/new-sofas-and-sectionals',
		buttonVariant: ButtonVariant.Hollow,
		clickHandler,
		headline: 'Meet Baylor',
		headlineButtonAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		headlineButtonContainerHorizontalAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		headlineButtonContainerVerticalAlignment: VERTICAL_ALIGNMENT.TOP,
		nudgeHorizontal: 0,
		nudgeVertical: 15,
		desktopVideoSettings: {
			thumbnailHeight: 400,
			thumbnailImage: 'https://embed-ssl.wistia.com/deliveries/309f100f73edd16a702de3b1a6b1f23a.jpg?image_crop_resized=960x400',
			thumbnailPriority: true,
			thumbnailWidth: 960,
			videoId: 'emr9obq8q3',
		},
		mobileVideoSettings: {
			thumbnailHeight: 960,
			thumbnailImage: 'https://embed-ssl.wistia.com/deliveries/c26b7d7ceeb1c69c3fba58e430ebf3d9.jpg?image_crop_resized=960x960',
			thumbnailPriority: true,
			thumbnailWidth: 960,
			videoId: '0972u3qmjc',
		}
	};

	return (
		<HeroWithVideo { ...heroArgs } />
	);
};
