import React, { MouseEventHandler } from 'react';

import { Link } from '~/components/Buttons/Components/Link';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';
import { MagicHeaderTag } from '~/components/magic-header';
import { TextLink } from '~/components/Buttons/Components/TextLink';

export const StorageGiveawayPromo = ({ clickHandler }: { clickHandler?: MouseEventHandler }) => {
	return (
		<>
			<div className="tw-bg-[#D4D0C4] tw-flex tw-justify-center tw-text-center md:tw-px-6 tw-px-1 md:tw-py-12 tw-py-10 tw-mb-2">
				<div className="tw-flex tw-flex-col tw-items-center tw-relative">
					<MagicHeaderTag headerLevel={2} className="tw-heading-3 md:tw-heading-1 tw-font-normal tw-mb-4">$4K Storage Giveaway</MagicHeaderTag>
					<p className="tw-body-2 tw-mb-4 tw-px-2">Now through January 12, enter for a chance to win a $4,000 gift card* and conquer clutter with modern storage solutions.</p>
					<Link
						onClick={clickHandler}
						className=""
						color={ButtonColor.Gray}
						href="https://woobox.com/3vrffj"
						isTransparent
						linkExternal={true}
						target="_blank"
						rel="noreferrer"
						variant={ButtonVariant.Hollow}
					>
					Enter Now
					</Link>
				</div>
			</div>
			<p className="tw-text-xs md:tw-block tw-mb-0 tw-text-gray-300 tw-text-center">*Void where prohibited. No purchase necessary to enter; a purchase will not increase your chance of winning. Subject to <TextLink href="https://woobox.com/3vrffj/rules" linkExternal={true} target="_blank"><span className={'tw-text-xs'}>Official Rules</span></TextLink>.</p>
		</>
	);
};
