import React from 'react';

import { Hero } from '~/components/hero/Hero';
import { MagicHeaderTag } from '~/components/magic-header';
import { HORIZONTAL_ALIGNMENT, IHeroWithBackgroundColor } from '~/components/hero/Hero.type';

import styles from '~/components/hero/Hero.module.scss';
import clearanceStyles from '~/components/hero/Components/ClearanceNov.module.scss';

export const CLEARANCENOV = ({ clickHandler = () => {} }) => {
	const heroArgs: IHeroWithBackgroundColor = {
		backgroundColor: '#a63535',
		clickHandler,
		headlineButtonAlignment: HORIZONTAL_ALIGNMENT.CENTER,
	};

	return (
		<Hero { ...heroArgs }>
			<div className={clearanceStyles.clearanceTitle}>
				<MagicHeaderTag headerLevel={2} className={`${styles.heroHeadline} tw-font-semibold md:tw-text-[180px] tw-text-[60px] tw-text-white tw-uppercase tw-leading-[0.69em]`}>Clearance</MagicHeaderTag>
			</div>
			<p className="tw-block tw-body-2 md:tw-heading-4 tw-font-semibold tw-text-white tw-mb-4 md:tw-mb-6">100s of new items added! Get 20-50% off discontinued furniture and decor.</p>
			<a className="HollowButton HollowButton--transparent HollowButton--white tw-text-lg" href="/clearance">Shop Clearance</a>
		</Hero>
	);
};
