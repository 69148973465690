import dynamic from 'next/dynamic';
import React from 'react';

import { DivideYWrapper } from '~/components/divide-y-wrapper/DivideYWrapper';
import { HomepageFeatCats } from '~/homepage/Components/HomepageFeatCats';
import { FullWidthContainer } from '~/components/full-width-container/FullWidthContainer';
import { HomepageHero } from '~/homepage/Components//HomepageHero';
import { HomepageTabbedShopping } from '~/homepage/Components/HomepageTabbedShopping';
import { useHomepageContext } from '~/homepage/Contexts/Homepage.context';
import Head from '~/layout/head/Components/Head';
import { PERSONALIZATION_SCOPE } from '~/personalization/Personalization.types';
import { PersonalizationDecisionModel } from '~/personalization/Models/PersonalizationDecision.model';
import { PromotionalFlex } from '~/homepage/Components/PromotionalFlex/PromotionalFlex';
import { s7ContentPath } from '~/global/global.constants';
import { ShoppingCarousel } from '~/components/shopping-carousel/ShoppingCarousel';

const ConfidenceMsg = dynamic(
	() => import('~/homepage/Components/ConfidenceMsgs/ConfidenceMsg').then(mod => mod.ConfidenceMsg),
	{ ssr: false },
);

const HomepageBestSellers = dynamic(
	() => import('~/homepage/Components/HomepageBestSellers').then(mod => mod.HomepageBestSellers),
	{ ssr: false },
);

const HomepageCoreCats = dynamic(
	() => import('~/homepage/Components/HomepageCoreCats').then(mod => mod.HomepageCoreCats),
	{ ssr: false },
);

const HomepageCustomerPhotos = dynamic(
	() => import('~/homepage/Components/HomepageCustomerPhotos').then(mod => mod.HomepageCustomerPhotos),
	{ ssr: false },
);

const HomepageNewOrExistingCustomer = dynamic(
	() => import('~/homepage/Components/HomepageNewOrExistingCustomer').then(mod => mod.HomepageNewOrExistingCustomer),
	{ ssr: false },
);

export const HomepageLayout = ({ heroPersonalizationDecisionModel }: { heroPersonalizationDecisionModel: PersonalizationDecisionModel | undefined }) => {
	const {
		homepageStore: {
			model: {
				hero,
				tabbedShopping,
				featCats,
				confidenceMsg,
				coreCats,
				bestSellers,
				customerPhotos,
				confidenceMsg2,
				promotionalFlex1,
				promotionalFlex2,
				shoppingCarousel,
			}
		},
	} = useHomepageContext();

	return <>
		<Head
			title="Modern Furniture - Room & Board"
			description="Modern furniture & home decor designed to fit your modern life. Explore handcrafted, American-made modern furniture at Room & Board."
			additionalScriptTags={
				<>
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: `
								{
									"@context": "http://schema.org",
									"@type": "Organization",
									"@id": "https://www.roomandboard.com/",
									"name": "Room & Board",
									"email": "shop@roomandboard.com",
									"url": "https://www.roomandboard.com",
									"telephone": "1-800-301-9720",
									"logo": "${s7ContentPath}/rnb-logo-black",
									"sameAs": [
										"https://www.facebook.com/roomandboard",
										"https://www.pinterest.com/roomandboard/",
										"https://www.instagram.com/roomandboard/",
										"http://www.houzz.com/pro/roomboard/room-board",
										"https://www.youtube.com/roomandboard",
										"https://www.wikidata.org/wiki/Q7366240",
										"https://twitter.com/roomandboard"
									]
								}
							`,
						}}
					/>
				</>
			}
		/>
		<h1 className="tw-sr-only">A leader in quality modern furniture since 1980</h1>
		<HomepageHero defaultHero={hero} heroPersonalizationDecisionModel={heroPersonalizationDecisionModel} />
		{Boolean(shoppingCarousel) &&
			<FullWidthContainer className="tw-mb-2 md:tw-mb-9">
				<ShoppingCarousel shoppingComponentKey={shoppingCarousel} />
			</FullWidthContainer>
		}
		{Boolean(tabbedShopping) &&
			<FullWidthContainer>
				<HomepageTabbedShopping />
			</FullWidthContainer>
		}
		<FullWidthContainer>
			<PromotionalFlex targetScope={PERSONALIZATION_SCOPE.FLEX_1} promoFlexDefault={promotionalFlex1} linkEventCompType="promotional flex 1" className="tw-py-7" />
		</FullWidthContainer>
		<HomepageNewOrExistingCustomer />
		{Boolean(featCats) &&
			<HomepageFeatCats />
		}
		<DivideYWrapper containerBorder="TOP">
			{Boolean(confidenceMsg) &&
				<ConfidenceMsg confMsg={confidenceMsg} linkEventCompType="confidence message 1" />
			}
			{Boolean(coreCats) &&
				<HomepageCoreCats />
			}
			{Boolean(confidenceMsg2) &&
				<ConfidenceMsg confMsg={confidenceMsg2} linkEventCompType="confidence message 2" />
			}
		</DivideYWrapper>
		<FullWidthContainer>
			{Boolean(bestSellers) &&
				<HomepageBestSellers />
			}
			<PromotionalFlex targetScope={PERSONALIZATION_SCOPE.FLEX_2} promoFlexDefault={promotionalFlex2} linkEventCompType="promotional flex 2" />
			{Boolean(customerPhotos) &&
				<HomepageCustomerPhotos />
			}
		</FullWidthContainer>
	</>;
};
