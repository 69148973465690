'use client';

import { clone } from 'mobx-keystone';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { PROMOTIONALFLEX_ID } from '~/homepage/Components/PromotionalFlex/PromotionalFlex.type';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { noop } from '~/util/noop';
import { PromotionalFlexs, isPromotionalFlexID } from '~/homepage/Components/PromotionalFlex/PromotionalFlexLibrary';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { type IPersonalizationComponentKey, PERSONALIZATION_SCOPE } from '~/personalization/Personalization.types';
import { PersonalizationDecisionModel } from '~/personalization/Models/PersonalizationDecision.model';

interface IProps {
	className?: string,
	linkEventCompType: string,
	promoFlexDefault?: PROMOTIONALFLEX_ID,
	targetScope?: PERSONALIZATION_SCOPE,
}

export const PromotionalFlex = observer((props: IProps) => {
	const {
		className = 'tw-py-4 md:tw-py-8',
		linkEventCompType,
		promoFlexDefault = undefined,
		targetScope,
	} = props;

	const {
		linkEventStore,
		personalizationStore = {},
		personalizationStore: {
			decisionsModel = undefined,
			sendDisplayEvent = noop,
			sendInteractEvent = noop,
		} = {},
	} = useGlobalContext();

	const promotionRef = useRef<HTMLDivElement>(null);
	const [isInView, setIsInView] = useState(false);
	const [promoFlexKey, setPromoFlexKey] = useState<IPersonalizationComponentKey | undefined>(promoFlexDefault);
	const [hasPersonaliationDecision, setHasPersonaliationDecision] = useState<Boolean | undefined>(false);
	const [personalizationDecision, setpersonalizationDecision] = useState<PersonalizationDecisionModel | undefined>();

	function handlePersonalizationDecision(decision: PersonalizationDecisionModel) {
		setHasPersonaliationDecision(true);
		setPromoFlexKey(decision.componentKey);
		setpersonalizationDecision(decision);
	}

	function handleIntersect(entries: IntersectionObserverEntry[]) {
		if (entries[0].isIntersecting) {
			setIsInView(true);
		}
	}

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 1,
		};

		let intersectionObserver: IntersectionObserver;

		if (promotionRef?.current) {
			intersectionObserver = new IntersectionObserver(handleIntersect, options);
			intersectionObserver.observe(promotionRef.current);
		}

		return () => {
			if (promotionRef.current && intersectionObserver) {
				intersectionObserver.unobserve(promotionRef.current);
			}
		};
	}, []);

	const Promotion = promoFlexKey && isPromotionalFlexID(promoFlexKey) ? PromotionalFlexs[promoFlexKey] : undefined;

	useEffect(() => {
		const linkEventTrackingData = {
			trLinkEventName: 'scrolled into viewport',
			trLinkEventCompType: linkEventCompType,
			trLinkEventCompName: promoFlexKey,
			trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
		};

		if (isInView && promoFlexKey) {
			linkEventStore.trackLinkEvent(linkEventTrackingData);
		}

		if (isInView && hasPersonaliationDecision) {
			sendDisplayEvent(personalizationDecision);
		}
	}, [isInView, promoFlexKey]);

	useEffect(() => {
		if (decisionsModel) {
			const decisionByScope = personalizationStore.getPersonalizationDecision(targetScope);

			if (decisionByScope) {
				const flexPersonalizationDecisionModel = new PersonalizationDecisionModel({ decision: clone(decisionByScope) });
				handlePersonalizationDecision(flexPersonalizationDecisionModel);
			}
		}
	}, [decisionsModel]);

	const promoClick = () => {
		if (!hasPersonaliationDecision) {
			return;
		}
		sendInteractEvent(personalizationDecision);
	};

	return (
		<div ref={promotionRef} data-tr-link-event-comp-name={promoFlexKey} data-tr-link-event-comp-type={linkEventCompType} className={cn({
			[className]: promoFlexKey,
		})}>
			{Promotion ? <Promotion clickHandler={promoClick} /> : null}
		</div>
	);
});
