import React from 'react';

import { Hero } from '~/components/hero/Hero';
import { HORIZONTAL_ALIGNMENT, IHeroWithBackgroundImage, VERTICAL_ALIGNMENT } from '~/components/hero/Hero.type';

export const FRESH_FINDS = ({ clickHandler = () => {} }) => {
	const heroArgs: IHeroWithBackgroundImage = {
		buttonText: 'Shop New',
		buttonLink: '/catalog/living/fresh-finds',
		clickHandler,
		headline: 'Fresh Finds',
		headlineClass: '',
		headlineButtonAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		headlineButtonContainerHorizontalAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		imgHorizontalAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		imgSrc: 'homepageHero_newArrivals_0924',
		imgHeight: 500,
		imgWidth: 500,
		headlineButtonContainerVerticalAlignment: VERTICAL_ALIGNMENT.TOP,
		nudgeHorizontal: 0,
		nudgeVertical: 10,
		imgNudgeHorizontalXS: -210,
	};

	return (
		<Hero { ...heroArgs } />
	);
};
