import React, { useState } from 'react';
import cn from 'classnames';

import { Link } from '~/components/Buttons/Components/Link';
import { MagicHeaderTag } from '~/components/magic-header';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';
import {
	HORIZONTAL_ALIGNMENT, VERTICAL_ALIGNMENT, IHeroWithVideo
} from '~/components/hero/Hero.type';
import { WistiaPlayerStore, createWistiaPlayerStore } from '~/components/wistia-player/Stores/WistiaPlayer.store';
import { WistiaPlayerWithThumbnail } from '~/components/wistia-player/Components/WistiaPlayerWithThumbnail';

import styles from '~/components/hero/Hero.module.scss';

export const HeroWithVideo = (props: IHeroWithVideo) => {
	const {
		buttonColor = ButtonColor.Gray,
		buttonText = '',
		buttonLink = '',
		buttonVariant = ButtonVariant.PrimaryOutline,
		clickHandler = () => {},
		desktopVideoSettings: {
			thumbnailHeight: desktopThumbnailHeight,
			thumbnailImage: desktopThumbnailImage,
			thumbnailPriority: desktopThumbnailPriority,
			thumbnailWidth: desktopThumbnailWidth,
			videoId: desktopVideoId,
		},
		headline = '',
		headlineButtonAlignment = '',
		headlineButtonContainerHorizontalAlignment = '',
		headlineButtonContainerVerticalAlignment = '',
		headlineClass = '',
		mobileVideoSettings: {
			thumbnailHeight: mobileThumbnailHeight,
			thumbnailImage: mobileThumbnailImage,
			thumbnailPriority: mobileThumbnailPriority,
			thumbnailWidth: mobileThumbnailWidth,
			videoId: mobileVideoId,
		},
		nudgeHorizontal = 0,
		nudgeVertical = 0,
		caption = '',
		children,
	} = props;

	const [desktopVideoStore] = useState<WistiaPlayerStore>(() => createWistiaPlayerStore(desktopVideoId));
	const [mobileVideoStore] = useState<WistiaPlayerStore>(() => createWistiaPlayerStore(mobileVideoId));

	const heroContainerClasses = cn(styles.heroContainer, {
		'tw-flex': headlineButtonContainerHorizontalAlignment === HORIZONTAL_ALIGNMENT.CENTER || headlineButtonContainerVerticalAlignment === VERTICAL_ALIGNMENT.CENTER,
		'tw-justify-center': headlineButtonContainerHorizontalAlignment === HORIZONTAL_ALIGNMENT.CENTER,
		'tw-items-center': headlineButtonContainerVerticalAlignment === VERTICAL_ALIGNMENT.CENTER,
	});

	const headlineButtonContainerClasses = cn(`${styles.heroHeadlineButtonContainer} tw-absolute tw-z-10`, {
		// classes for header and button alignment in relation to eachother
		'tw-text-center': headlineButtonAlignment === HORIZONTAL_ALIGNMENT.CENTER,
		'tw-text-left': headlineButtonAlignment === HORIZONTAL_ALIGNMENT.LEFT,
		'tw-text-right': headlineButtonAlignment === HORIZONTAL_ALIGNMENT.RIGHT,
		// classes for the hero-content container dealing with horizontal alignment
		[`${styles.horizontalCenter} tw-left-1/2 tw-transform -tw-translate-x-1/2 sm:tw-w-full`]: headlineButtonContainerHorizontalAlignment === HORIZONTAL_ALIGNMENT.CENTER,
		[`${styles.horizontalLeft} tw-left-0`]: headlineButtonContainerHorizontalAlignment === HORIZONTAL_ALIGNMENT.LEFT,
		[`${styles.horizontalRight} tw-right-0`]: headlineButtonContainerHorizontalAlignment === HORIZONTAL_ALIGNMENT.RIGHT,
		// classes for the hero-content container dealing with vertical alignment
		[`${styles.verticalCenter} tw-top-1/2 tw-transform -tw-translate-y-1/2`]: headlineButtonContainerVerticalAlignment === VERTICAL_ALIGNMENT.CENTER,
		[`${styles.verticalTop} tw-top-0`]: headlineButtonContainerVerticalAlignment === VERTICAL_ALIGNMENT.TOP,
		[`${styles.verticalBottom} tw-bottom-0`]: headlineButtonContainerVerticalAlignment === VERTICAL_ALIGNMENT.BOTTOM,
	});

	return (
		<div data-qa="hero-container" className={`${heroContainerClasses}`}>
			<div data-qa="hero-content" className={headlineButtonContainerClasses}
			//@ts-ignore
				style={{ '--nudgeHorizontal': `${nudgeHorizontal}px`, '--nudgeVertical': `${nudgeVertical}px` }}>
				{ children && children }
				{ !children &&
					<>
						<MagicHeaderTag headerLevel={2} className={`${styles.heroHeadline} ${headlineClass}`}>{headline}</MagicHeaderTag>
						{ caption &&
							<p className="tw-body-2 tw-mb-3">
								{caption}
							</p>
						}
						<Link
							onClick={clickHandler}
							color={buttonColor}
							variant={buttonVariant}
							href={buttonLink}
						>{buttonText}</Link>
					</>
				}
			</div>
			<div data-qa="hero-video" className={styles.videoContainer}>
				<div className="tw-hidden md:tw-block">
					<WistiaPlayerWithThumbnail
						store={desktopVideoStore}
						thumbnailHeight={desktopThumbnailHeight}
						thumbnailImage={desktopThumbnailImage}
						thumbnailPriority={desktopThumbnailPriority}
						thumbnailWidth={desktopThumbnailWidth}
						videoId={desktopVideoId} />
				</div>
				<div className="tw-block md:tw-hidden">
					<WistiaPlayerWithThumbnail
						store={mobileVideoStore}
						thumbnailHeight={mobileThumbnailHeight}
						thumbnailImage={mobileThumbnailImage}
						thumbnailPriority={mobileThumbnailPriority}
						thumbnailWidth={mobileThumbnailWidth}
						videoId={mobileVideoId} />
				</div>
			</div>
		</div>
	);
};
