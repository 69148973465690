import React from 'react';

import { Hero } from '~/components/hero/Hero';
import { MagicHeaderTag } from '~/components/magic-header';
import { HORIZONTAL_ALIGNMENT, IHeroWithBackgroundColor } from '~/components/hero/Hero.type';

import styles from '~/components/hero/Hero.module.scss';

export const CLEARANCE = ({ clickHandler = () => {} }) => {
	const heroArgs: IHeroWithBackgroundColor = {
		backgroundColor: '#a63535',
		clickHandler,
		headlineButtonAlignment: HORIZONTAL_ALIGNMENT.CENTER,
	};

	return (
		<Hero { ...heroArgs }>
			<div className="tw-border-b tw-border-white md:tw-px-3 xl:tw-px-10 tw-mb-4 md:tw-mb-8 tw-text-center">
				<MagicHeaderTag headerLevel={2} className={`${styles.heroHeadline} tw-font-semibold tw-text-white tw-mb-1`}>clearance</MagicHeaderTag>
			</div>
			<span className="tw-block tw-body-2 md:tw-heading-4 tw-font-semibold tw-text-white tw-mb-4 md:tw-mb-6">100s of new items added! Get 20-50% off discontinued furniture and decor.</span>
			<a className="HollowButton HollowButton--transparent HollowButton--white tw-text-lg" href="/clearance">Shop Clearance</a>
		</Hero>
	);
};
