import Flicking from '@egjs/react-flicking';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import type {
	Panel, ReadyEvent, VisibleChangeEvent, WillChangeEvent
} from '@egjs/react-flicking';
import type { RefObject } from 'react';

import { ButtonBase } from '~/components/Buttons/Components/ButtonBase';
import { EVENTS } from '~/components/Slider/Slider.constants';
import { flickingMethod } from '~/components/Slider/Slider.utils';
import { without } from '~/util/without';

import styles from '~/components/Slider/SliderDots.module.scss';

export const SliderDot = ({
	dotClassName,
	flickingRef,
	index,
	onClick,
	panelCount,
	trLinkEventTrack = false,
}: {
	dotClassName?: string | undefined,
	flickingRef: RefObject<Flicking>,
	index: number,
	onClick?: ({ index }: { index: number }) => void,
	panelCount: number,
	trLinkEventTrack?: boolean,
}) => {
	const [isCurrent, setIsCurrent] = useState(false);

	const [isSmall, setIsSmall] = useState(false);

	function updateIsCurrent({ currentIndex = 0 }) {
		setIsCurrent(index === currentIndex);
	}

	function updateIsSmall({
		panels,
		visiblePanels,
	}: {
		panels: Panel[],
		visiblePanels: Panel[],
	}) {
		const panel = panels[index];

		const invisiblePanels = without(panels, ...visiblePanels);

		const firtAndLastPanels = [panels[0], panels[panels.length - 1]];

		const smallPanels = without([...invisiblePanels, visiblePanels[0], visiblePanels[visiblePanels.length - 1]], ...firtAndLastPanels);

		setIsSmall(smallPanels.includes(panel));
	}

	async function handleClick() {
		await flickingMethod(async () => {
			await flickingRef.current?.moveTo(index);
		});

		onClick?.({ index });
	}

	function handleReady({
		currentTarget: {
			index: currentIndex,
			panels,
			visiblePanels,
		},
	}: ReadyEvent<Flicking>) {
		updateIsCurrent({ currentIndex });

		updateIsSmall({
			panels,
			visiblePanels,
		});
	}

	function handleVisibleChange(visibleChangeEvent: VisibleChangeEvent<Flicking>) {
		const {
			currentTarget: {
				panels,
			},
			visiblePanels,
		} = visibleChangeEvent;

		updateIsSmall({
			panels,
			visiblePanels,
		});
	}

	function handleWillChange({ index: currentIndex	}: WillChangeEvent<Flicking>) {
		updateIsCurrent({ currentIndex });
	}

	useEffect(() => {
		flickingRef.current?.on?.(EVENTS.READY, handleReady);

		flickingRef.current?.on?.(EVENTS.VISIBLE_CHANGE, handleVisibleChange);

		flickingRef.current?.on?.(EVENTS.WILL_CHANGE, handleWillChange);

		return () => {
			flickingRef.current?.off?.(EVENTS.READY, handleReady);

			flickingRef.current?.off?.(EVENTS.VISIBLE_CHANGE, handleVisibleChange);

			flickingRef.current?.off?.(EVENTS.WILL_CHANGE, handleWillChange);
		};
	}, [flickingRef.current]);

	return (
		<ButtonBase
			className={
				classNames(styles['slider-dot'], {
					[styles['slider-dot-current']]: isCurrent,
					[styles['slider-dot-small']]: isSmall,
					[dotClassName || '']: dotClassName,
				})
			}
			data-qa="slider-dot-button"
			data-tr-link-event-name="dot-navigation"
			data-tr-link-event-track={trLinkEventTrack}
			onClick={handleClick}
		>
			<span className="tw-sr-only">
				View item {index + 1} of {panelCount}
			</span>
		</ButtonBase>
	);
};
