import React from 'react';
import cn from 'classnames';

import { Link } from '~/components/Buttons/Components/Link';
import { S7Image } from '~/components/Images/S7Image';
import { s7ImagePath } from '~/global/global.constants';
import { MagicHeaderTag } from '~/components/magic-header';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';
import {
	HORIZONTAL_ALIGNMENT, IHeroWithBackgroundColor, IHeroWithBackgroundImage, VERTICAL_ALIGNMENT, hasBackgroundImage, hasBackgroundColor
} from '~/components/hero/Hero.type';

import styles from '~/components/hero/Hero.module.scss';

export const Hero = (props: IHeroWithBackgroundColor | IHeroWithBackgroundImage) => {
	const {
		buttonColor = ButtonColor.Gray,
		buttonText = '',
		buttonLink = '',
		buttonVariant = ButtonVariant.PrimaryOutline,
		clickHandler = () => {},
		headline = '',
		headlineButtonAlignment = '',
		headlineButtonContainerHorizontalAlignment = '',
		headlineButtonContainerVerticalAlignment = '',
		headlineClass = '',
		nudgeHorizontal = 0,
		nudgeVertical = 0,
		caption = '',
		children,
	} = props;

	const heroWithBackgroundColor = hasBackgroundColor(props);
	const heroWithBackgroundImage = hasBackgroundImage(props);

	const heroContainerClasses = cn(styles.heroContainer, {
		'tw-flex': headlineButtonContainerHorizontalAlignment === HORIZONTAL_ALIGNMENT.CENTER || headlineButtonContainerVerticalAlignment === VERTICAL_ALIGNMENT.CENTER,
		'tw-justify-center': headlineButtonContainerHorizontalAlignment === HORIZONTAL_ALIGNMENT.CENTER,
		'tw-items-center': headlineButtonContainerVerticalAlignment === VERTICAL_ALIGNMENT.CENTER,
		[styles.heroBackgroundColor]: heroWithBackgroundColor,
	});

	const headlineButtonContainerClasses = cn(styles.heroHeadlineButtonContainer, {
		// classes for header and button alignment in relation to eachother
		'tw-text-center': headlineButtonAlignment === HORIZONTAL_ALIGNMENT.CENTER,
		'tw-text-left': headlineButtonAlignment === HORIZONTAL_ALIGNMENT.LEFT,
		'tw-text-right': headlineButtonAlignment === HORIZONTAL_ALIGNMENT.RIGHT,
		// classes for the hero-content container dealing with horizontal alignment
		[`${styles.horizontalCenter} tw-left-1/2 tw-transform -tw-translate-x-1/2 sm:tw-w-full`]: headlineButtonContainerHorizontalAlignment === HORIZONTAL_ALIGNMENT.CENTER,
		[`${styles.horizontalLeft} tw-left-0`]: headlineButtonContainerHorizontalAlignment === HORIZONTAL_ALIGNMENT.LEFT,
		[`${styles.horizontalRight} tw-right-0`]: headlineButtonContainerHorizontalAlignment === HORIZONTAL_ALIGNMENT.RIGHT,
		// classes for the hero-content container dealing with vertical alignment
		[`${styles.verticalCenter} tw-top-1/2 tw-transform -tw-translate-y-1/2`]: headlineButtonContainerVerticalAlignment === VERTICAL_ALIGNMENT.CENTER,
		[`${styles.verticalTop} tw-top-0`]: headlineButtonContainerVerticalAlignment === VERTICAL_ALIGNMENT.TOP,
		[`${styles.verticalBottom} tw-bottom-0`]: headlineButtonContainerVerticalAlignment === VERTICAL_ALIGNMENT.BOTTOM,
		// set the position of content to be absolute if not on colored background
		'tw-absolute tw-z-[1]': !heroWithBackgroundColor,
	});

	return (
		<div data-qa="hero-container" className={`${heroContainerClasses}`}
			//@ts-ignore
			style={{ '--backgroundColor': heroWithBackgroundColor ? props.backgroundColor : null }}>
			<div data-qa="hero-content" className={headlineButtonContainerClasses}
			//@ts-ignore
				style={{ '--nudgeHorizontal': `${nudgeHorizontal}px`, '--nudgeVertical': `${nudgeVertical}px` }}>
				{ children && children }
				{ !children &&
					<>
						<MagicHeaderTag headerLevel={2} className={`${styles.heroHeadline} ${headlineClass}`}>{headline}</MagicHeaderTag>
						{ caption &&
							<p className="tw-body-2 tw-mb-3">
								{caption}
							</p>
						}
						<Link
							onClick={clickHandler}
							color={buttonColor}
							variant={buttonVariant}
							href={buttonLink}
						>{buttonText}</Link>
					</>
				}
			</div>
			{ heroWithBackgroundImage &&
				<div data-qa="hero-image" className={styles.imgContainer}>
					<S7Image
						alt=""
						className={styles.heroImg}
						//@ts-ignore
						style={{ '--imgHorizontalAlignment': props.imgHorizontalAlignment, '--imgNudgeHorizontalXS': `${props.imgNudgeHorizontalXS}px` }}
						priority={true}
						src={`${s7ImagePath}/${props.imgSrc}`}
						fill={true}
					/>
				</div>
			}
		</div>
	);
};
