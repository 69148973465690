import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

import { HERO_ID } from '~/components/hero/Components/HomepageHeroes.type';
import { Heroes, isHeroID } from '~/components/hero/Components/HomepageHeroesLibrary';
import type { PersonalizationDecisionModel } from '~/personalization/Models/PersonalizationDecision.model';
import { useGlobalContext } from '~/global/Contexts/Global.context';

import styles from '~/homepage/Components/HomepageHero.module.scss';

export const HomepageHero = ({ defaultHero, heroPersonalizationDecisionModel }: { defaultHero?: HERO_ID | undefined, heroPersonalizationDecisionModel?: PersonalizationDecisionModel }) => {
	const {
		personalizationStore: {
			sendInteractEvent = () => {},
			postDisplayEvent = () => {},
		} = {},
	} = useGlobalContext();

	// default hero from settings file
	const DefaultHeroComponent = defaultHero ? Heroes[defaultHero] : undefined;

	// hero component from personalization decision
	const HeroDecisionComponent = heroPersonalizationDecisionModel?.componentKey && isHeroID(heroPersonalizationDecisionModel.componentKey) ? Heroes[heroPersonalizationDecisionModel.componentKey] : null;

	const compName = HeroDecisionComponent ? heroPersonalizationDecisionModel?.componentKey : defaultHero;

	if (!DefaultHeroComponent && !HeroDecisionComponent) {
		return null;
	}

	useEffect(() => {
		// use postDisplay event rather than sendDisplayEvent because we get the hero decision on the server
		// sendDisplayEvent requires that window.alloy exists
		if (HeroDecisionComponent) {
			postDisplayEvent({
				cookies: Cookies.get(),
				decision: heroPersonalizationDecisionModel,
			});
		}
	});

	const heroClick = () => {
		if (!HeroDecisionComponent) {
			return;
		}
		sendInteractEvent(heroPersonalizationDecisionModel);
	};

	return (
		<div data-qa="hero" className={styles.HeroFullWidth} data-tr-link-event-comp-name={compName} data-tr-link-event-comp-type="hero">
			{HeroDecisionComponent && <HeroDecisionComponent clickHandler={heroClick} />}
			{Boolean(!HeroDecisionComponent && DefaultHeroComponent) && <DefaultHeroComponent />}
		</div>
	);
};
